import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "./Checkbox";
import Style from "style-it";
import SearchField from "../common/SearchField";
import 'react-toastify/dist/ReactToastify.css';
import Scrollbar from "react-scrollbars-custom";
import instance from "../../axios";
import Loader from "../../Layout/Loader";
import TextField from "../common/TextField";
import { MenuItem, Typography } from "@mui/material";
import axios from "axios";


let contractTypes = [
    { id: 4, name: "All" },
    { id: 1, name: "Maintenance" },
    { id: 2, name: "Leasing" },
    { id: 3, name: "Cash" },
  ];


  const TargetAudience = [
    {
      key: "1",
      name: "Users",
    },
    {
      key: "2",
      name: "Admin",
    },
    {
      key: "3",
      name: "accountAdmin",
    },
  ];
  
let source;
let debounceTimer;

class AccountSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      vehicles: [],
      isChecked:false,
      list:{},
      usersId: {},
      loader: true,
      loader1: false,
      contractType:4,
      targets:[]
    };
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      usersId: {},
      search: "",
      isChecked: false,
    });
  }
  componentWillMount() {
    let list = {};
    let usersId = {};

    if (this.props.value && this.props.value.length) {
      this.props.value.map((d) => (list[d] = true));
      this.setState({ list, loader: false });
    }
    if (this.props.value2 && this.props.value2.length) {
      this.props.value.map((d) => (usersId[d] = true));
      this.setState({ usersId, loader: false });
    }
      this.getAccounts();
    if (this.props.sendAll) this.sendToParent();
  }

  componentWillReceiveProps(nextprops) {
    let list = {};
    let usersId = {};
    if (nextprops.value && nextprops.value.length) {
      nextprops.value.map((d) => ((list[d] = true), (usersId[d] = true)));
    }
    this.setState({ list });
    const prevProps = this.props;
    if(prevProps.tab !== nextprops.tab){
      this.setState({
        isChecked: false
      })
    }
  }

  sendToParent() {
    if (this.props.onChange) {
      this.props.onChange(
        Object.keys(this.state.list),
        Object.keys(this.state.usersId).map(Number),
        this.state.contractType === 4 &&  this.state.isChecked && this.state.targets.length>2
      );
    }
  }

  onChange = (e, v) => {
    let list = { ...this.state.list };
    let usersId = { ...this.state.usersId };
    if (e.target.checked) {
      list[v.contractNumber] = true;
      usersId[v.userId] = true;
    } else {
      delete list[v.contractNumber];
      delete usersId[v.userId];
    }
    this.setState(
      {
        usersId,
        list,
        isChecked: this.props.vehicles?.length === Object.keys(list).length,
      },
      () => {
        this.sendToParent();
      }
    );
  };
 

  toggleItems = (event) => {
    if (event.target.checked) {
      let list = {};
      let usersId = {};
      this.state.vehicles.map(
        (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
      );

      this.setState({ list, usersId, isChecked: true }, () => {
        this.sendToParent();
      });
    } else {
      this.setState({ list: {}, usersId: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
  };


  handleChange  = (name , event) => { 
    this.setState({
        [name]:event.target.value,
        isChecked: false,
        loader: true,
        list:{},
        usersId: {}, 
    },()=>this.getAccounts())
  }
  searchItem = (text) => {
    source.cancel();

    this.setState({ loader1: true, search: text.target.value }, () => {
      // setTimeout(() => {
        this.getAccounts();
      // }, 1000); 
    });
  };
  replaceHtmlEntities = (obj) => {
    if (typeof obj === 'string') {
      obj = obj.replace(/amp;/, ' ').replace(/amp;/g, '');
      const tempElement = document.createElement('div');
      tempElement.innerHTML = obj;
      return tempElement.innerText;
    } else if (Array.isArray(obj)) {
      return obj.map((item) => this.replaceHtmlEntities(item));
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, this.replaceHtmlEntities(value)])
      );
    } else {
      return obj;
    }
  };
  getAccounts = () => {
    let apiUrl;
      if(this.state.contractType === 4){
        apiUrl = `/api/users/get?all=true&contractType=1&contractType=2&contractType=3&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.search}`;
      }
       else{
         apiUrl = `/api/users/get?all=true&contractType=${this.state.contractType}&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.search}`;
       }
       const selectedTargets = this.state.targets; // Get the selected options

       // Add the selected target values to the URL
       selectedTargets.forEach((target) => {
         apiUrl += `&userType=${target}`;
       });

       
  source = axios.CancelToken.source();

    if (this.props.logInUser && this.props.logInUser.id) {
        // this.setState({  loader: true},()=>{ 
      instance({
        method: "GET",
        url:apiUrl ,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
       cancelToken: source.token,
      })
        .then((res) => {
          const newArray = res.data.map(item => ({
            id: item.entity.id,
            userId: item.entity.id,
            contractNumber: item.extraInfo.contractNumber,
            name: item.entity.name,
          }));
          this.setState({ vehicles: this.replaceHtmlEntities(newArray) || [], loader: false, loader1: false });
        
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
        // })
    }
  };


  handleChangeMode = (event) => {
    const checkboxValue = event.target.value;
    const updatedTargets = [...this.state.targets]; // Make a copy of the current state
    const index = updatedTargets.indexOf(checkboxValue);

    if (index > -1) {
      // Remove the checkbox value from the state if it's already selected
      updatedTargets.splice(index, 1);
    } else {
      // Add the checkbox value to the state
      updatedTargets.push(checkboxValue);
    }
    if (event.target.checked) {
      let list = {};
      let usersId = {};
      this.setState({ list, usersId, isChecked: true }, () => {
        this.sendToParent();
      });
    } else {
      this.setState({ list: {}, usersId: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
    

    this.setState({ targets: updatedTargets, loader: true,isChecked:false }, () => {
      // This callback is called after the state update
      this.getAccounts()
    });
  };
  

  render() {
    return (
      <>
        {this.state?.loader === false ? (
          <Style>
            {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
         
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
        }
        .selector-list {
            padding: 0;
            margin: 0;
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}

            <div>
              <div
                className="section-head clearfix section-head-filter"
                style={{ padding: 0,
                    //  marginLeft: -6, marginRight: -2 
                    }}
              >
                <div>
                    <TextField
                    id="contractType"
                    select
                    label={this.props.translate("contractType")}
                    value={this.state.contractType || ""}
                    onChange={(e)=>this.handleChange("contractType", e)}
                    SelectProps={{
                    MenuProps: {
                    // className: classes.menu,
                    },
                    }}
                    margin="dense"
                    fullWidth
                    // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                    required
                    >
                    {contractTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                    {this.props.translate(option.name)}
                    </MenuItem>
                    ))}
                    </TextField> 
                    </div>

                  <div>
                    <Typography variant="h6" gutterBottom 
                      style={{ fontSize: "16px", fontWeight: 700 , marginLeft:20}}>
                        {this.props.translate("sendMessageTo")}
                        </Typography>
                        <div 
                        style={{display:"flex", justifyContent:"space-between", alignItems:"center" , padding:"0px 10px"}}
                        // className="selector-list"
                        >
                           {TargetAudience.map((row) => (
                              <div key={row.key} style={{display:"flex", justifyContent:"space-between",  alignItems:"center"}}>
                                <input
                                  type="checkbox"
                                  id={row.key}
                                  value={row.key}
                                  checked={this.state.targets.includes(row.key)}
                                  onChange={this.handleChangeMode}
                                  style={{cursor:"pointer"}}
                                />
                                <label htmlFor={row.key} style={{marginLeft:"5px"}}>{this.props.translate(row.name)}</label>
                              </div>
                            ))}
                      </div>
                     </div>

                <ul className="filter-row pull-left">
                  <li>
                    <SearchField
                      label={this.props.translate("searchAccounts")}
                      type="search"
                      placeholder="exp: ID, Name"
                      value={this.state.search}
                      onChange={this.searchItem}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </li>
                </ul>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={this.toggleItems}
                  label={this.props.translate("selectAll")}
                />
              </div>

              <div className="selector-count">
                {Object.keys(this.state.usersId).length}{" "}
                {this.props.translate("AccountsSelected")}
              </div>
              <Scrollbar
                disableTracksWidthCompensation={true}
                style={{
                  height:
                    (this.props.minHeight
                      ? parseInt(this.props.minHeight)
                      : 35) * (this.props.rows || 5),
                }}
                scrollerProps={{
                  renderer: (props) => {
                    const { elementRef, ...restProps } = props;
                    return (
                      <div {...restProps} ref={elementRef} id="scrollableDiv" />
                    );
                  },
                }}
              >
                <ul className="selector-list">
                  {!this.state.loader1 ? (this.state.vehicles &&
                    this.state.vehicles.map((row) => (
                      <li key={row.contractNumber}>
                        <label
                          className={
                            this.state.list[row.contractNumber]
                              ? "active"
                              : null
                          }
                        >
                          <span className="selector-check">
                            <Checkbox
                              checked={this.state.list[row.userId]}
                              // checked
                              value={row}
                              onChange={this.onChange}
                              // onClick={() => this.props.selecteItem(this.props.data)}
                            />
                          </span>
                          <span className="selector-label">
                            {row.name || row.accountName || row.label} - {row?.contractNumber}
                          </span>
                        </label>
                      </li>
                    ))) : (
                      <div style={{height:"250px"}}><Loader  component="filter" /></div>
                    )}
                </ul>
              </Scrollbar>
            </div>
          </Style>
        ) : (
          <div style={{height:"320px"}}>
          <Loader  component="filter" />
          </div>
        )} 
      </>
    );
  }
}

const mapState = (state) => ({
  vehicles: state.vehicles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps((AccountSelector));
