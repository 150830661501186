import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomDialog from "../common/Dialog";
import AddVehicleModal from "./addVehicle";
import Table from "../common/tableWithBackEndPagination";
import isEqual from "react-fast-compare";
import { checkPrivileges, errorHandler } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import VehicleFilerModal from "./VehicleFilterModal";
// import DevicePortList from './devicePortList'
import Button from "../common/Button";
import { Grid } from "@mui/material";
import TextField from "../common/TextField";
import Style from "style-it";
import moment from "moment";
import instance from "../../axios";
import ExportImportMenu from "../common/ExportImportMenu";

// import {
//   removedvehicle,
//   updatevehicle,
//   addvehicle,
// } from "../../Actions/Vehicles";
import Loader from "../../Layout/Loader";

import {ViewUserDetail} from "./Components/viewUnitDetail";
// import Table1 from "../../Components/common/TableServices";
import Table1 from "../../Components/common/TableWithColors";
import EnhancedTable from "./TableServices";
// 
const vehicleDataFormat = (data, itemId, user) => ({
  id: itemId ? itemId : 0,
  attributes: { ...data.attributes, 
  insurancePercentage: data.attributes.insurancePercentage ? parseFloat(data.attributes.insurancePercentage) : 0
 },
  deviceId: data?.deviceId?.id || "",
  label: data.label,
  modelId: data?.modelId?.id || "",
  fuelEfficiency :data.fuelEfficiency ?  parseFloat(data.fuelEfficiency) : 0,
  odometer :data.odometer ?  parseFloat(data.odometer) : 0,
  garage: data.garage,
  entitlement: data.entitlement ?   parseFloat(data.entitlement) : 0,
  // vehicleType: data.vehicleType,
  vin: data.vin,
  vehicleLicensePlate: data.vehicleLicensePlate,
  leasePeriod: data && data.leasePeriod ? parseInt(data.leasePeriod) : "",
  vehicleLisencePlateArabic: data.vehicleLisencePlateArabic,
  totalAvailablekm: data.totalAvailablekm ? parseFloat(data.totalAvailablekm) : 200,
  expirationTime: data.expirationTime,
  monthlyRate: data && data.monthlyRate ? parseFloat(data.monthlyRate) : "",
  purchasePrice:
    data && data.purchasePrice ? parseFloat(data.purchasePrice) : "",
  purchaseDate: data?.purchaseDate || "",
  deliveryDate: data?.deliveryDate || "",
  leaseEndDate: data?.leaseEndDate || "",
  leaseStartDate: data?.leaseStartDate || "",
  areaId: data && data.areaId ? data.areaId.id : "",
  category: data && data.category ? data.category : "",
  onLease: data && data.onLease ? data.onLease : false,
  parentId:data && data.parentId ? data.parentId : user&&user.id?user.id : 0,
  carbonFootPrint: data && data.carbonFootPrint ? data.carbonFootPrint : 0
  // parentId:user && user.id?user.id : 0
});


const trasmissionList = [
  { name: "Manual",key: "manual" },
  { name: "Automatic" , key: "automatic"},
];
const fuel_typeList = [
  { name: "Gasoline",key: "Gasoline" },
  { name: "Diesel" , key: "Diesel"},
  { name: "Ethanol" , key: "Ethanol"},
  { name: "Hybrid" , key: "Hybrid"},
  { name: "Electric" , key: "Electric"},
  { name: "Biodiesel", key: "Biodiesel" },
  { name: "Compressed Natural Gas (CNG)" , key: "Compressed Natural Gas (CNG)"},
  { name: "Liquefied Petroleum Gas (LPG)" , key: "Liquefied Petroleum Gas (LPG)"},
  { name: "Hydrogen" , key: "Hydrogen"},
  { name: "Mild Hybrid Electric Vehicle (HEV)" , key: "Mild Hybrid Electric Vehicle (HEV)"},
  { name: "Moderate Hybrid Electric Vehicle (HEV)" , key: "Moderate Hybrid Electric Vehicle (HEV)"},
  { name: "Plug-in Hybrid Electric Vehicle (PHEV)" , key: "Plug-in Hybrid Electric Vehicle (PHEV)"},
]
const formDefault = {
  label: "",
  garage: "",
  model: "",
  // vehicleType: "",
  expirationTime: "",
  vehicleLicensePlate: "",
  vehicleLisencePlateArabic: "",
  totalAvailablekm: 200,
  leasePeriod: "",
  onLease: false,
  fuelEfficiency:"",
  category: "",
  attributes: { typeName: ["Maintenance"] }, //vehicleLicensePlate: ''
  carbonFootPrint: 0,
  odometer: 0,
  entitlement:0
};
let source;
let cachedTags = null;
let fuel_type = "";
class vehicleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addOption: false,
      editOption: false,
      vehiclesData: "",
      page: 1,
      pageSize: 20,
      form: { ...formDefault },
      trasmissionList: trasmissionList,
      fuel_typeList: fuel_typeList,
      selectedVehicle: "",
      // addOption: false,
      // editOption: false,
      isVisableTrackerModal: false,
      isVisableUserBtn: false,
      vehicleId: "",
      vehicleTracker: "",
      // form: { ...formDefault },
      vehicleStatus: "",
      allMakes: "",
      trackerData: "",
      selectedMakeTypes: "",
      onDeleteConfirmation: false,
      itemSearch: "",
      searchContractText: "",
      areas: "",
      defaultAccountsList: "",
      selectedUser: "",
      categories: "",
      selecteditem:'',
      loader1:false,
      showImportedInvoiceError: false,
      selectedUserCheck:false,
      tagSearch:false,
      alltags:{},
      open: true,
      tag_1: '',
      tag_2: '',
      tag_3: '',
      tag_4: '',
      tag_5: '',
      enableReset: false,
      vehicleLicensePlateError: false,
      allVehiclesValid:false,
      isImportBtnDisabled: true,
      rowsPerPage:10,
      isVehicleStatus: false,
      vinError:true,

      // minPercentage: 1,
      // maxPercentage: 100,
      // categoriesList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);

    this.handleChangeForAttributesValues =
      this.handleChangeForAttributesValues.bind(this);
    this.handleChangeLicenseExpDate =
      this.handleChangeLicenseExpDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateVehicle = this.updateVehicle.bind(this);
    // this.removeEnable = this.removeEnable.bind(this);
    // this.deleteVehicle = this.deleteVehicle.bind(this);
  }


  componentWillMount() {
      this.getVehivlesList({}, true);
       this.getMoreUsers();
     
  }
  resetVehicleValidation = () => {
    this.setState({
      vehicleLicensePlateError: false
    })
  }
  enableUpdateButton = () => {
    this.setState({
      isVisableUserBtn: false
    })
  }
  // downloadMakeModelList = () => {
  //   const filePath = '/assets/MakeModelList.txt';
  //   // Construct the full URL to the file
  //   const fileUrl = process.env.PUBLIC_URL + filePath;
  //   // Create a link element
  //   const link = document.createElement('a');
  //   // Set the href attribute to the file URL
  //   link.href = fileUrl;
  //   // Specify that the link should download the file
  //   link.setAttribute('download', '');
  //   // Append the link to the document body
  //   document.body.appendChild(link);
  //   // Trigger a click on the link to start the download
  //   link.click();
  //   // Remove the link from the document body
  //   document.body.removeChild(link);
  // };
  openModale = () => {
     this.fetchCategories();
      this.getDevicesList();
      this.fetchMake();
      this.getAreas();
      // this.getFuelEfficiency();
      // this.getMoreUsers();
    this.setState({
      addOption: true,
      editOption: false,
      isVisableUserBtn: false,
      form: { ...formDefault },
    });
  };
 
 
  onEdit = async (items) => {
    await Promise.all([
      this.fetchCategories(),
      this.getDevicesList(),
      this.fetchMake(),
      this.getAreas(),
    ]);
    let fuelEfficiency ;

    let item = items;
   

    
    if (item) {
      const [z, device] = await Promise.all([
        this.fetchSelectedModel(item.modelId),
        item.deviceId !== 0 ? this.fetchDevice(item.deviceId) : '',
      ]); 
      if(item.category){
        fuelEfficiency =  await this.getFuelEfficiency(item.category);
      }

      const isOwnerOrAuthorized = item.parentId === this.props.logInUser.id ||
        (Array.isArray(this.state.defaultAccountsList) &&
          this.state.defaultAccountsList.some(owner => owner.userId === item.parentId));
  
      this.setState({
        form: {
          ...item,
          modelId: z && z.id ? { id: z.id, label: z.modelName } : "",
          make: z && z.id ? { id: z.makeName, key: z.makeName, label: z.makeName } : "",
          deviceId: device && device.id ? { id: device.id, key: device.id, label: device.name } : "",
        },
        fuelEfficiency:item.fuelEfficiency ? item.fuelEfficiency :  fuelEfficiency || '',
        selectedVehicle: item,
        fetchModelId: true,
        editOption: true,
        addOption: false,
        selectedUserCheck: isOwnerOrAuthorized ? true : false,
        vinError: item?.vin?.length === 17 ? false : true,
      });
    }
  };
  

  replaceHtmlEntities = (text) => {
    let itemname = text.replace(/amp;/, ' ').replace(/amp;/g, '');
    const textarea = document.createElement('textarea');
    textarea.innerHTML = itemname;
    return textarea.value;
  }


  getMoreUsers = () =>{
    fetch(`/api/users/get?userId=${this.props.logInUser.id}&all=true&limit=-1`, {

      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
        response.json().then(res => {
          if(res.status ==='success'){
            let data = res.data
          // const transformedUsers = users.map((user) => ({
          //   name: user.entity.name,
          //   email: user.entity.email,
          //   e: user.entity.e,
          // }));


            let result = data.data.map(item => ({
              ...item.entity,
              label: this.replaceHtmlEntities(item.entity.label || item.entity.name),
              name: this.replaceHtmlEntities(item.entity.name),
              value: item.entity.id
            }))
            if (result.length === 0) {
              this.setState({
                resultMessage: 'No option found',
                defaultOptions: ''
              })
            } else {
              this.setState({
                resultMessage: '',
                defaultAccountsList: result
              })
            }
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
         
              toast.error(
                this.props.translate(err[1])
            )
          }
          else {
            throw response
          }
        })
     
    }).catch(e => {
      console.log('e =', e)
      //   this.props.dispatch(toast.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }



  onCloseImportedModal = () => {
    this.setState({
      showImportedInvoiceError: false,
      showRegeneratedModal: false,
      // bulkInvoicesDate: "",
    });
  };

  fetchCategories = () => {
    //(/api/devices/categories
    fetch(`/api/vehicles/categories`)
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              this.setState({ categories: res.data.categories });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
             
                toast.error(
                 this.props.translate(err[1])
              );
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
      });
  };



  getDevicesList = async () => {
    let { page, pageSize } = this.state;
    let result = await axios.get(`/api/devices/list`);
    this.setState({
      trackerData: result?.data?.data?.data || [],
    });
  };

 

  updateVehicle = async () => {
    // event.preventDefault();
    let { selectedVehicle, form } = this.state;
    const obj = vehicleDataFormat(form, selectedVehicle.id, this.state.selectedUser);
    
    let result = await axios.put(`/api/vehicles/${selectedVehicle.id}`, obj);

    if (result.data.status === "success") {
      this.setState({
        addOption: false,
        editOption: false,
        isVisableUserBtn: false,
        selectedUser:"",
        form: "",
      });
   
        toast.success(
           this.props.translate("Vehicle Updated")
      );
      this.getVehivlesList();
    } else {
      
        toast.error(
         this.props.translate(result.data.message)
      );
    }
  };

  handleChangeLicenseExpDate = (name) => (event) => {
    // if (name === 'expirationTime') {
      const dateValue = event ? event.toISOString() : null;

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: dateValue,
        },
      },
      () => this.checkRequiredFields()
    );
  };

  fetchMake = () => {
    instance({
      method: "GET",
      url: `/api/models/make`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let defaultOptions =
          response &&
          response.categories &&
          response.categories.map((item) => {
            return { id: item, label: item };
          });
        this.setState({ allMakes: defaultOptions });
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };



  async fetchVehicleMake(selectedMake) {
    return await instance({
      method: "GET",
      url: `/api/models/get?limit=1000&make=${selectedMake}&search= `,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let defaultOptions =
          response &&
          response.data &&
          response.data.map((item) => {
            return { ...item, label: item.modelName };
          });
        return defaultOptions;
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }


  
  handleChange1 = async  (name, event) => {
    if(name==="trasmissionType"){
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...this.state.form.attributes, [name]: event.id, }
            
          },
        },() => this.checkRequiredFields()
      );
      }
      else if (name === "make") {
        const z = await this.fetchVehicleMake(event.label);
        this.setState({
          selectedMakeTypes: z,
          form: {
            ...this.state.form,
            [name]: event,
            modelId: {},
            attributes: { ...this.state.form.attributes, make: event.label, }

          },
        },() => this.checkRequiredFields());
      }
      else if (name === "modelId") {

        this.setState({
          form: {
            ...this.state.form,
            [name]: event,
            attributes: { ...this.state.form.attributes, model: event.modelName, }

          },
        },() => this.checkRequiredFields());
      }
      else if(name==="deviceId"){
        this.setState({
          form: {
            ...this.state.form,
            [name]: event,
          },
        });
      }
    }


  handleChangeForAttributesValues = async (name, event, link) => {

    let { attributes } = this.state.form;
    if (name === "type") {
      this.setState({
        form: {
          ...this.state.form,
          attributes: {
            ...this.state.form.attributes,
            [name]: event.target.checked,
          },
        },
      });
    } else if (name === "make") {

      const z = await this.fetchVehicleMake(event.label);
      this.setState({
        selectedMakeTypes: z,
        form: {
          ...this.state.form,
          [name]: event,
          modelId: {},
          attributes: { ...this.state.form.attributes, make: event.label, }

        },
      }, () => this.checkRequiredFields());
    } else if (name === "modelId") {
      this.setState({
        form: {
          ...this.state.form,
          [name]: event,
        },
      }, () => this.checkRequiredFields());
    }
    else if (name === "areaId") {
      this.setState({
        form: {
          ...this.state.form,
          [name]: event,
        },
      });
    }
    else if (name === "searchUnits") {
      this.setState({
        form: {
          ...this.state.form,
          [name]:  event.target.value,
        },
      });
    }
    else {
      const target = name === "trasmissionType" ? event : event && event.target;
      let value =
        name === "trasmissionType" ? event : target && target.value.toString();
      if (value) {
        if (
          name === "insurancePercentage" ||
          name === "bankPercentage" ||
          name === "Maintenance" ||
          name === "zakatPercentage" ||
          name === "resalePercentage" ||
          name === "replacement" ||
          name === "otherCost" ||
          name === "fines" ||
          name === "brandingCost" ||
          name === "telematicsCost" ||
          name === "depreciationCost" ||
          name === "driverCost" ||
          name === "standByVehicle"
        ) {
          if (
            name === "insurancePercentage" ||
            name === "bankPercentage" ||
            name === "resalePercentage" ||
            name === "replacement" ||
            name === "zakatPercentage"
          ) {
            if (value > 100) {
              value = 100;
            }
            if (value < 0) {
              value = 0;
            }
          }
          attributes[name] = parseFloat(value);
        } else attributes[name] = event.target.value;
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: { ...attributes },
            },
          },
          () => {
            if (this.state.editOption) {
              this.setState({
                isVisableUserBtn: true,
              });
            }
          }
        );
      } else {
        delete attributes[name];
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: { ...attributes },
            },
          },
          () => {
            if (this.state.editOption) {
              this.setState({
                isVisableUserBtn: true,
                waslLinked: false,
              });
            }
          }
        );
      }
    }
  };

  
  handleChangeUser = (name, event) => {
    if(name === "owner"){
      this.setState({
        owner: event,
      });
    }else
    {this.setState({
      selectedUser: event,
    });}
  };
  
  checkRequiredFields() {
    let { label, vehicleLicensePlate , deliveryDate, leaseEndDate, leaseStartDate, onLease, make, modelId, vin, category, totalAvailablekm} = this.state.form;
    let { fuel_type } = this.state.form.attributes;
    let value = true;
    if (this.state.editOption) {
      value = !isEqual(this.state.form, this.props.selecteditem);
    }
    let validLicensePlateFormat = /^\d{4}[A-Za-z]{3}$/;
      let validLicensePlateFormatArabic = /^[\u0621-\u064A]\s[\u0621-\u064A]\s[\u0621-\u064A]\s[\d٠-٩]{4}$/;
      if(validLicensePlateFormat.test(vehicleLicensePlate) || validLicensePlateFormatArabic.test(vehicleLicensePlate)) {
        this.setState({
          vehicleLicensePlateError: false
        })
      } else {
        this.setState({
          vehicleLicensePlateError: true
        })
      }
    if(onLease){
      if (
        label &&
        label.trim().length &&
        vehicleLicensePlate &&
        vehicleLicensePlate.trim().length &&
        (validLicensePlateFormat.test(vehicleLicensePlate) || validLicensePlateFormatArabic.test(vehicleLicensePlate)) &&
        deliveryDate && 
        leaseEndDate&&
        leaseEndDate > leaseStartDate &&
        value &&
        // make && 
        // modelId && 
        vin && 
        category && 
        fuel_type &&
        totalAvailablekm && totalAvailablekm > 1
      ) {
        this.setState({
          isVisableUserBtn: true
        });
      } 
      else {
        this.setState({
          isVisableUserBtn: false
        });
      }
    }
else{
  if (
    label &&
    label.trim().length &&
    vehicleLicensePlate &&
    vehicleLicensePlate.trim().length &&
   ( validLicensePlateFormat.test(vehicleLicensePlate) || validLicensePlateFormatArabic.test(vehicleLicensePlate)) &&
    // fuel_type !== '' && 
    // fuel_type !== undefined &&
    // deliveryDate && 
    // leaseEndDate&&
    // leaseEndDate > leaseStartDate &&
    value && 
    // make && 
    // modelId && 
    vin && vin?.length === 17 &&
    category && 
    fuel_type &&
    totalAvailablekm && totalAvailablekm > 0
  ) {
    this.setState({
      isVisableUserBtn: true
    });
  } 
  else {
    this.setState({
      isVisableUserBtn: false
    });
  }
}

  
  }
  handleChange(name, event) {
    const { target } = event;
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } 
    else if (name === "fuel_type") {
      // fuel_type = event.name;
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...this.state.form.attributes, [name]: event.id, }
          },
        },
        () => this.checkRequiredFields()
      );
    }
    else if (name === "category") {
      let value = target.value;
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]:value,
          },
        },
        () =>{ this.checkRequiredFields(); this.getFuelEfficiency(value) }
      );
    }
    else if (name === "areaId") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event,
          },
        },
        () => this.checkRequiredFields()
      );
    } else if (name === "typeName") {
      this.setState({
        form: {
          ...this.state.form,
          attributes: {
            ...this.state.form.attributes,
            typeName: event,
          },
        },
      },() => this.checkRequiredFields());
    } else if (name === "entitlement") {
      this.setState({
        form: {
          ...this.state.form,
          [name]: target.value,
        },
      }, () => this.checkRequiredFields());
    } else {
      let value = target.value.toString();
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
           vinError : name === 'vin' && value.length !== 17 ? 
                    "VIN must be exactly 17 characters long." : ""
        },
        () => this.checkRequiredFields()
      );
    }
  }
  onChangedDevice = (name,item) => {
    if (item) {
      const device = this.props?.devices?.data?.find((d) => d.id === item.id);

      this.setState(
        {
          vehicleTracker: {
            value: device.id,
            label: device.name,
            uniqueId: device.uniqueId,
          },
          form: {
            ...this.state.form,
            deviceId: item.value,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          vehicleTracker: "",
          form: {
            ...this.state.form,
            deviceId: "",
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  modalControle = () => {
    this.setState({
      addOption: false,
      editOption: false,
      form: "",
    });
  };
  

  async fetchDevice  (id) {
    return await instance({
       method: 'GET',
       url: `/api/devices/${id}`,
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json'
       }
     })
       .then(response => {
         return response
       })
       .catch(error => {
         errorHandler(error, this.props.dispatch)
       })
   }

 
  async fetchSelectedModel  (id) {
    return await instance({
       method: 'GET',
       url: `/api/models?modelId=${id}`,
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json'
       }
     })
       .then(response => {
         return response[0]
       })
       .catch(error => {
         errorHandler(error, this.props.dispatch)
       })
   }

   async fetchSelectedUser  (id) {
    return await instance({
       method: 'GET',
       url: `/api/users/${id}`,
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json'
       }
     })
       .then(response => {
         return response
       })
       .catch(error => {
         errorHandler(error, this.props.dispatch)
       })
   }

  //  async getFuelEfficiency  (type) {
  //   return await instance({
  //      method: 'GET',
  //      url: `/api/vehicles/fuelefficiency?type=${type}`,
  //      headers: {
  //        Accept: 'application/json',
  //        'Content-Type': 'application/json'
  //      }
  //    })
  //      .then(response => {
  //       this.setState({ 
  //         form: {
  //         ...this.state.form,
  //           fuelEfficiency: "",
  //       },})
  //        return response
  //      })
  //      .catch(error => {
  //        errorHandler(error, this.props.dispatch)
  //      })
  //  }

   getFuelEfficiency = async (type) => {
    let result = await axios.get(`/api/vehicles/fuelefficiency?type=${type}`);
    const [key] = Object.keys(result.data);
    const value = result.data[key];
            this.setState({ 
            form: {
               ...this.state.form,
              fuelEfficiency: value || 0,
          },})
          return value
      };

  getAreas = async () => {
    let result = await axios.get(`api/areas `);
    this.setState({
      areas: result?.data.data || [],
    });
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  setPagination = () => {
    // if (
    //   this.state.vehiclesData &&
    //   this.state.vehiclesData &&
    //   this.state.vehiclesData.length
    // ) {
    let nAcc = { ...this.state.vehiclesData };
    let lastPage = nAcc.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nAcc.data;
    nAcc.pageSize = this.state.pageSize;
    nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nAcc;
    // } else {
    //   return {};
    // }
  };

  handleChangeRowsPerPage1 = (rowsPerPage) => {
    this.setState({page :1, rowsPerPage: rowsPerPage });
  };

  handleChangePage1 = (value) => {
    this.setState({
      page: value,
      rowsPerPage: this.state.rowsPerPage
    });
  };

  handleChangePage = (value) => {
    this.setState({ page: value }, () => {
      this.getVehivlesList();
    });
  };

  removeEnable = (selected) => {
    if (selected[0]) {
      this.setState({
        selectedVehicle: selected[0],
        onDeleteConfirmation: true,
        form: "",
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        form: "",
      });
    }
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
 
  
  tagSearch = () => {

      this.setState({
        tagSearch: true,
        tagsModelOpen:true
      });
  };
  resetModelSearch = () => {

    this.setState({
      enableReset: false,
      // tagsModelOpen:false,
      alltags: {},
    },()=>this.getVehivlesList());
};
  closetagSearch = () => {
    this.setState({
      tagSearch: false,
    });
};


getVehivlesList = (tags, check) => {
  let { page, pageSize } = this.state;
  let apiUrl;
  source = axios.CancelToken.source();

  apiUrl = `api/vehicles/get?page=${page}&limit=${pageSize}&userId=${this.props.logInUser.id}&all=true&search=${this.state.searchContractText}`;

  // Create an array to hold the tag parameters
  const tagParams = [];

  // Check each tag for a value and add it to the tagParams array if it has a value
  if (tags) {
    if (tags.tag_1) tagParams.push(`tag1=${tags.tag_1}`);
    if (tags.tag_2) tagParams.push(`tag2=${tags.tag_2}`);
    if (tags.tag_3) tagParams.push(`tag3=${tags.tag_3}`);
    if (tags.tag_4) tagParams.push(`tag4=${tags.tag_4}`);
    if (tags.tag_5) tagParams.push(`tag5=${tags.tag_5}`);
  }

  // Join the tag parameters with '&' to create the final query string

  // Use a Set to ensure unique values and then convert it back to an array
  const uniqueTagParams = Array.from(new Set(tagParams));
  const tagQueryString = uniqueTagParams.join('&');

  if (tagQueryString) {
    apiUrl += `&${tagQueryString}`;
  }

  const alltags = {
    tag_1: [],
    tag_2: [],
    tag_3: [],
    tag_4: [],
    tag_5: [],
  };

  this.setState(
    {
      loader1: true,
    },
    () => {
      axios
        .get(apiUrl, {
          cancelToken: source.token,
        })
        .then((response) => {
         let tagsData =  response?.data.data?.data.forEach((t) => {
            if (t.attributes.tag_1) alltags.tag_1.push(t.attributes.tag_1);
            if (t.attributes.tag_2) alltags.tag_2.push(t.attributes.tag_2);
            if (t.attributes.tag_3) alltags.tag_3.push(t.attributes.tag_3);
            if (t.attributes.tag_4) alltags.tag_4.push(t.attributes.tag_4);
            if (t.attributes.tag_5) alltags.tag_5.push(t.attributes.tag_5);
          });

          // Check if tags need to be cached
          if (check) {
            cachedTags = { ...alltags };
          }
      
          this.setState({
            vehiclesData: response?.data.data || [],
            loader1: false,
            tagSearch: false,
            // enableReset:tags?true:false,
            enableReset: !!(tags && Object.keys(tags).length),
            alltags: cachedTags, // Store tags in component's state
          });
        })
        .catch((e) => {
          // Handle error
        });
    }
  );
};

getVehiclesFromModel = (vehicles) => {
  this.setState({
    page:1, 
    // pageSize:vehicles.length,
    vehiclesData:  {page: 1, pageSize: 20, total: vehicles.length, hasNext: true,data:vehicles},
    loader1:false,
    tagSearch: false,

  });
}

  deleteVehicle = async () => {
    const { selectedVehicle } = this.state;
    let result = await axios.delete(`/api/vehicles/${selectedVehicle.id}`);
    if (result.data.status === "success") {
      // this.getFilteredPAyments(this.state.to,this.state.from,this.state.account,this.state.vehcile,this.state.area)
    
        toast.success(
       this.props.translate("Vehicle Deleted")
      );
      this.setState({ 
           selectedVehicle: '' ,
           onDeleteConfirmation: false,
          }, () => {
              this.getVehivlesList();
            });
      // this.getVehivlesList();
    } else {
      
        toast.error(
        this.props.translate(result.data.message)
      );
    }
  };

  searchContractText = (e) => {
    source.cancel();
    this.setState(
      {
        searchContractText: e.target.value,
      },
      () => {
        this.getVehivlesList();
      }
    );
  };

  openImportTable = (items,user) => {
   
    let newArray1;
    let  userId  = user ? user.id : this.props.logInUser.id
    let status = false

    if(user==="vehicleStatus"){
    
      newArray1 = items.filter(item => !item.attributes.invalid);
      this.setState({ isVehicleStatus: true })
    }else {
      newArray1 = items.map(item => {
        const { attributes: { invalidList, ...restAttributes }, modelName, makeName, ...restItem } = item;
         // Create a new object without the invalidList, modelName, makeName property
         const newObj = {
          ...restItem,
          attributes: restAttributes,
        };
         return newObj;
       });
       this.setState({ isVehicleStatus: false })
    }
 
  //  if(status === false) {
     this.setState({ isImportBtnDisabled: false })
  //  } else {
  //    this.setState({ isImportBtnDisabled: true })
  //  }
     this.setState({
       allVehiclesValid:status,
       showImportedInvoiceError: true,
       importedInvoiceData: items,
       selectedUserId:userId,
       selectedVehicleList:newArray1
     });
   };


  SubmitImport = (e) => {
    const objFor =   this.state.selectedVehicleList && this.state.selectedVehicleList
    const url = this.state.isVehicleStatus ? `/api/vehicles/vehiclewrappersstatus`:`/api/vehicles/vehiclewrappers?userId=${this.state.selectedUserId}`
    instance({
      url: url,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: objFor,
    })
      .then((res) => {
      
          toast.success(
             this.props.translate("Total " +
            res.total +
            "   " +
            "  Added " +
            "   " +
            res.added +
            "    " +
            " Skipped " +
            "   " +
            res.skipped)
        );
        // this.onCloseColorModel();
        this.setState({
          showImportedInvoiceError: false,
        },
        ()=>{this.onCloseImportedModal(); this.getVehivlesList();});
      })
      .catch((err) => {
        const splitString = err.message.split(": ");
        let result;
        if (splitString.length >= 2) {
           result = splitString[1]; // Take the second part after splitting
        } 
       
          toast.error(
            this.props.translate(result)
        );
      });
  };

  handleSubmit = async () => {
    // event.preventDefault();
    let { form } = this.state;

    
    
    const obj = vehicleDataFormat(form, '', this.state.selectedUser);


    let result = await axios.post(`/api/vehicles`, obj);
   
    if (result.data.status === "success") {
      
      // this.props.dispatch( addvehicle(result.data.data));

      this.setState({
        addOption: false,
        editOption: false,
        isVisableUserBtn: false,
        selectedUser:"",

        form: { ...formDefault },
      });
     
        toast.success(
         this.props.translate("Vehicle Added")
      );
      this.getVehivlesList();
    } else {
  
        toast.error(
         this.props.translate(result.data.message)
      );
    }
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({page:1, pageSize: rowsPerPage }, () => {
      this.getVehivlesList();
    });
  };

  getVehicleData = async () => {
    const {vin} = this.state.form
    let result = await axios.get(`/api/trigger/vindecoder?vin=${vin}`);

    let vehicleData;
        // vehicleData =  result.data || {}
    if(result.data.statusCode === "400"){
       vehicleData = {};
       this.setState({
        form: {
          ...this.state.form,
        }})
          toast.error( this.props.translate("vehicleNotFound")
        );
      }else{
        vehicleData =  result.data || {}
        this.setState({
          form: {
            ...this.state.form,
            // modelId:vehicleData.modelId,
            makeName:vehicleData.makeName,
            modelName:vehicleData.modelName,
            modelId: vehicleData && vehicleData.modelId ? { id: vehicleData.modelId , label: vehicleData.modelName  || vehicleData.attributes.model } : "",
            make: vehicleData.makeName  && vehicleData.makeName ? { id: vehicleData.makeName, key: vehicleData.makeName, label: vehicleData.makeName } : "",
            attributes:{
              ...this.state.form.attributes,
              make:vehicleData.attributes.make,
              model:vehicleData.attributes.model,
              variant:vehicleData.attributes.variant,
              year:vehicleData.attributes.year,
            }
          },
        });
    }
  }
  
  render() {
    return (
      <div>
        <Style>{`
            .custom-tab-button {
              min-height: 29px;
              border-radius: 6px 6px 0 0;
              margin: 0 2px;
              background: ${this.props.themecolors["600"]};
              color: ${this.props.themecolors["themeInverse"]};
            }
            .custom-tabs {
              min-height: 30px;
              margin: 10px 6px 0;
            }

            .custom-tab-button-selected {
              background: ${this.props.themecolors["500"]};
              color: ${this.props.themecolors["themeInverse"]};
            }`}</Style>

        {this.state.addOption ? (
          <Fragment>
            <AddVehicleModal
              {...this.state}
              {...this.props}
              getVehicleData={this.getVehicleData}
              title={this.props.translate("addVehicle")}
              formSubmit={this.handleSubmit}
              handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
              handleChangeForAttributesValues={
                this.handleChangeForAttributesValues
              }
              handleChange1={this.handleChange1}
              onChangedDevice={this.onChangedDevice}
              form={this.state.form}
              handleChange={this.handleChange}
              handleChangeUser={this.handleChangeUser}
              modalControle={this.modalControle}
              buttonText={this.props.translate("sharedCreate")}
              resetVehicleValidation={this.resetVehicleValidation}
              creatingUnit
            />
          </Fragment>
        ) : this.state.editOption ? (
          <Fragment>
            <ViewUserDetail
            {...this.state}
            {...this.props}
            getVehicleData={this.getVehicleData}
            selectedUser={this.state.selectedUser}
            title={this.props.translate("updateVehicle")}
            formSubmit={this.updateVehicle}
            // form={this.state.selectedVehicle}
            form={this.state.form}
            handleChange={this.handleChange}
            onChangedDevice={this.onChangedDevice}
            handleChangeUser={this.handleChangeUser}
            handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
            handleChangeForAttributesValues={
              this.handleChangeForAttributesValues
            }
            getVehivlesList={this.getVehivlesList}
            handleChange1={this.handleChange1}
            modalControle={this.modalControle}
            buttonText={this.props.translate("Update")}
            resetVehicleValidation={this.resetVehicleValidation}
            enableUpdateButton={this.enableUpdateButton}
            creatingUnit/>
            {/* <AddVehicleModal
              {...this.state}
              {...this.props}
              title={this.props.translate("updateVehicle")}
              formSubmit={this.updateVehicle}
              // form={this.state.selectedVehicle}
              form={this.state.form}
              handleChange={this.handleChange}
              handleChangeUser={this.handleChangeUser}
              handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
              handleChangeForAttributesValues={
                this.handleChangeForAttributesValues
              }
              handleChange1={this.handleChange1}
              modalControle={this.modalControle}
              buttonText={this.props.translate("Update")}
              creatingUnit
            /> */}
          </Fragment>
        ) : (
          <>
            <Grid
              container
              className="breadcrumb-row"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: 0 }}>
                  {this.props.translate("vehicles")}
                </h3>
              </Grid>
              <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
              <div style={{ width: "15%", marginRight: 15 }}>
              <Button
                  // disabled={!(checkPrivileges("vehicleCreate") ? true : false)}
                  aria-label="modelSearch"
                  size="small"
                  color="inherit"
                  onClick={this.tagSearch}
                  id="modelSearch"
                >
                  {this.props.translate("modelSearch")}
                </Button>
                </div>
                <div style={{ width: "15%", marginRight: 15 }}>
              <Button
                  disabled={
                    !this.state.enableReset}
                  aria-label="reset"
                  size="small"
                  color="inherit"
                  onClick={this.resetModelSearch}
                  id="reset"
                >
                  {this.props.translate("reset")}
                </Button>
                </div>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.state.searchContractText}
                    onChange={(e) => this.searchContractText(e)}
                  />
                </div>
               
                <Button
                  disabled={!(checkPrivileges("vehicleCreate") ? true : false)}
                  aria-label="Create"
                  size="small"
                  color="inherit"
                  onClick={this.openModale}
                  id="createButton"
                >
                  {this.props.translate("create")}
                </Button>
                <div style={{ width: "10%", marginRight: 35 }}>
                  <ExportImportMenu
                    fileName="vehicles"
                    checkPrivilege="serviceCreate"
                    isServices
                    // data={this.props?.invoicesData?.data || []}
                    themecolors={this.props.themecolors}
                    categoryName={this.props?.selectedServiceTypeId || ""}
                    openImportTable={this.openImportTable}
                    translate={this.props.translate}
                    // downloadMakeModelList={this.downloadMakeModelList}
                  />
                </div>
                {/* <Button
                    aria-label="Back"
                    size="small"
                    color="inherit"
                    onClick={this.props.goBack}
                    id="back"
                    style={{ margin: 10 }}
                  >
                    {this.props.translate("back")}
                  </Button> */}
              </Grid>
            </Grid>
            {this.state.loader1 ?  <Loader/> : 
            <Table
              rows={Array.isArray(this.state.vehiclesData) ? this.state.vehiclesData : (this.state.vehiclesData?.data || [])}
              // rows={this.state.vehiclesData?.data || []}
              pagination={this.setPagination()}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              translate={this.props.translate}
              // rowsPerPage={15}
              SearchItem={this.SearchItem}
              handleChangePage={this.handleChangePage}
              isEditable
              onClick={this.onEdit}
              canUpdate
              isCursoPointer
              // canDelete
              // onEdit={this.onEdit}
              onDelete={this.removeEnable}
              hasAccessOfDelete={checkPrivileges("vehicleDelete")}
              hasAccessOfUpdate={checkPrivileges("vehicleUpdate")}
              themecolors={this.props.themecolors}
              // contractNumber={this.props?.selectedAccount?.contractNumber || ''}
              // downloadInvoicePdf={this.downloadInvoicePdf}
              // leasing={this.props.leasing}
              rowDefinition={[
                {
                  id: "label",
                  // numeric: false,
                  // disablePadding: false,
                  label: this.props.translate("label"),
                },
                {
                  id: "category",
                  // numeric: false,
                  // disablePadding: false,
                  label: this.props.translate("vehicleType"),
                },
                {
                  id: "vehicleLicensePlate",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("vehicleLicensePlate"),
                },
                {
                  id: "attributes.parentName",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("account"),
                },
                {
                  id: "make",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("make"),
                },
                {
                  id: "purchaseDate",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("purchaseDate"),
                },
                {
                  id: "year",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("year"),
                },
                {
                  id: "vin",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("vin"),
                },
                {
                  id: "monthlyRate",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("monthlyRate"),
                },
                // {
                //   id: "saleTotalAmount",
                //   numeric: false,
                //   disablePadding: false,
                //   label: this.props.translate("Total Amount"),
                // },
                {
                  id: "deliveryDate",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("deliveryDate"),
                  // hide: false,
                },
                {
                  id: "attributes.depreciationCost",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("depreciationCost"),
                  // hide: false,
                },
                // {
                //   id: 'valid',
                //   numeric: false,
                //   disablePadding: false,
                //   label: this.props.translate('valid')
                // },
              ]}
            />
  }
          </>
        )}


        {this.state.tagSearch ? (
              <VehicleFilerModal
              {...this.props}
              alltags={this.state.alltags}
              getVehiclesFromModel={this.getVehiclesFromModel}
              getVehiclesList={this.getVehivlesList}
              vehiclesData= {this.state.vehiclesData?.data || []}
              tags={this.props.tags}
              onCancel={this.closetagSearch}
              onOk={this.closetagSearch}
              onClose={this.closetagSearch}
              showSubmitbtn
              userTags={this.props.logInUser.attributes}

              title={this.props.translate("vehicleFilter")}
              // children={ }
            />
          ) : null}

      {this.state.showImportedInvoiceError && (
            <CustomDialog
              title="Imported Vehicle"
              visable={true}
              onClose={this.onCloseImportedModal}
              bodyPadding={10}
              hideDragIcon
              fullWidth
              isVisableBtn
              noFullScreen
              showSubmitbtn
              // isImportBtnDisabled={this.state.isImportBtnDisabled}
              isImportBtnDisabled={false}
              maxWidth={"lg"}
              handleSubmit={this.SubmitImport}
            >
              {/* {this.state?.allVehiclesValid ?  */}

              {this.state.isVehicleStatus ? 
                <EnhancedTable
                  rows={this.state.importedInvoiceData || []}
                  isCursoPointer
                  rowDefinition={ [
                      {
                        id: "vehicleLicensePlate",
                        label: this.props.translate("vehicleLicensePlate"),
                      },
                      {
                        id: "vehicleStatus",
                        label: this.props.translate("status"),
                      }
                    ]}
                /> 
              :
          <Table1
          rows={this.state.importedInvoiceData || []}
          disableHead
          // allUsersId={this.props.allUsersId}
          // showCheckbox
          // handleChangeRowsPerPage={this.handleChangeRowsPerPage1}
          // handleChangePage={this.handleChangePage1}
          isChecked={this.props.isChecked}
          translate={this.props.translate}
          rowsPerPage={10}
          isEditable={false}

          // ServerSetting={this.props.ServerSetting}
          themecolors={this.props.themecolors}
          // checkHandleChange={this.unitToUserpermission}
          canAssign
          canRemove
          // setSerialNo
          isCursoPointer
          rowDefinition={
            this.state.isVehicleStatus ? [
              {
                id: "vehicleLicensePlate",
                label: this.props.translate("vehicleLicensePlate"),
              },
              {
                id: "vehicleStatus",
                label: this.props.translate("status"),
              }
            ]:
            this.props.ServerSetting.contractType === 2 ? [
              {
                id: "label",
                label: this.props.translate("label"),
              },
              {
                id: "category",
                label: this.props.translate("vehicleType"),
              },
              {
                id: "vehicleLicensePlate",
                label: this.props.translate("vehicleLicensePlate"),
              },
              
              {
                id: "make",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("make"),
              },
              {
                id: "model",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("model"),
              },
              {
                id: "category",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("category"),
              },
              {
                id: "capacity",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("capacity"),
              },
              {
                id: "vin",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("vin"),
              },
              {
                id: "garage",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("garage"),
              },
              {
                id: "deliveryDate",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("deliveryDate"),
              },
              {
                id: "leaseStartDate",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("leaseStartDate"),
              },
              {
                id: "leaseEndDate",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("leaseEndDate"),
              },
           
          ] : [
            {
                id: "label",
                label: this.props.translate("label"),
              },
              {
                id: "category",
                label: this.props.translate("vehicleType"),
              },
              {
                id: "vehicleLicensePlate",
                label: this.props.translate("vehicleLicensePlate"),
              },
              
              {
                id: "make",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("make"),
              },
              {
                id: "model",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("model"),
              },
              {
                id: "category",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("category"),
              },
              {
                id: "capacity",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("capacity"),
              },
            
              {
                id: "vin",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("vin"),
              },
              {
                id: "garage",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("garage"),
              },
            ]}
          />  }
          {/* : "All Vehicles are Valid"} */}
            </CustomDialog>
          )}



        {this.state.onDeleteConfirmation && (
          <>
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.deleteVehicle}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.selectedVehicle.label}
            />
          </>
        )}
      </div>
    );
  }
}
const mapState = (state) => {

  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const VehicleList = mapStateToProps((vehicleList));
