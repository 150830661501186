import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../../wrapper";
import { ShowCoupon } from "../../Components/Coupon";
import { logInUserInfo } from "../../Actions/Users";
import { checkPrivileges, isOwnerOrDealer } from "../../Helpers";
import { AddCommutator } from "../../Components/Commutator/index";
import axios from "axios";
import withRoutes from "../../HOC/HocRoutes";

const formDefault = {
  title: "",
  message: "",
  notificators: [],
  // accountIds: [],
};
const makeGrageData = (data, userIds) => ({
  ...data,
  userIds:userIds|| [],
  notificators: data.notificators.join(', ') || "",
});


let source;
class commutator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userFetch: false,
      addOption: false,
      editOption: false,
      garagesData: "",
      form: { ...formDefault },
      isVisableAddBtn: false,
      selectedWorkShop: "",
      onDeleteConfirmation: false,
      page: 1,
      pageSize: 20,
      searchGarage: "",
      loader1: false,
      garageCity:"",
      garageType:"",
      isVisableFilterBtn:false,
      enableReset:false,
      contractNumber: [],
      selectedaccountId: [],
      accounts: "",
      newUser: [],
      sendAll:false


    };
  }
  onCloseDueMaintenanceModel = () => {
    this.setState({
      openFilterModal: false,
      to: "",
      from: "",
      isSubmitBtn: false,
      selectedDate: "",
      contractNumber: [],
      newUser: [],
      countChecked: 0,
      anchorEl: null,
    });
  };
  handleChangeAccount = (name, userIds, check) => {
  this.setState({
    newUser: userIds,
    sendAll:check
    // newUser: e,
  });
  this.setState({ newUser: userIds }, () => {
    let filter = [];
    Object.entries(this.state.newUser).map(([key, value]) => {
      if (value) {
        filter.push(value);
        this.setState({});
      }
    });
    this.setState({
      selectedaccountId: check ? [] : filter,
    });
  });

}
 
  checkRequiredFields() {
    let { title, message, notificators } = this.state.form;
    
    
    if (title && message  && notificators?.length>0) {
      this.setState({
        isVisableAddBtn: true,
      });
    } else {
      this.setState({
        isVisableAddBtn: false,
      });
    }
  }
  handleChangeTabs = (e) => {
    this.setState(
      {
        form : {
          ...this.state.form,
          title:e
        }
        // selectedApp: e,
      }
    );
 
};
  handleChange = (name) => (event) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: event.target.value,
        },
      },
      () => this.checkRequiredFields()
    );
  };


 handleChangeMode = (event) => {
    const checkboxValue = event.target.value;
    const currentNotificators = this.state.form.notificators || []; // Default to an empty array if notificators is falsy

    const updatedMode = [...currentNotificators];

    if (updatedMode.includes(checkboxValue)) {
      updatedMode.splice(updatedMode.indexOf(checkboxValue), 1);
    } else {
      updatedMode.push(checkboxValue);
    }

    // setMode(updatedMode);
    this.setState(
      {
        form: {
          ...this.state.form,
          notificators:updatedMode
        }
      },()=>this.checkRequiredFields()
      )
  };

  handleSubmit = async () => {
    let { form } = this.state;
     const obj = makeGrageData(form, this.state.selectedaccountId);

    let result;
    result = await axios.post(`/api/broadcasts`, obj);
    if (result.data.status === "success") {
     this.setState({
       form:"",
       selectedaccountId:[],
       newUser:[],
       isVisableAddBtn: false,
     });
     
       toast.success(
          this.props.translate("messageBroadCasted")
     );
   } else {
     this.setState({
       form:"",
       isVisableAddBtn: false,
     });
    
       toast.error(
          this.props.translate(result.data.message)
     );
   }
 };




  render() {
    if (this.props.logInUser) {
      return (
        <Layout {...this.props} noSidebar>
          {/* commutator */}
          <AddCommutator
            {...this.props}
            {...this.state}
            handleChangeAccount={this.handleChangeAccount}
            handleChangeMode={this.handleChangeMode}
            addOption={this.state.addOption}
            modalControle={this.modalControle}
            addGarages={this.addGarages}
            data={this.state.form}
            handleChange={this.handleChange}
            handleChangeTabs={this.handleChangeTabs}
            isVisableAddBtn={this.state.isVisableAddBtn}
            checkRequiredFields={this.checkRequiredFields}
            handleSubmit={this.handleSubmit}
            onCancel={this.onCancel}
          />
        </Layout>
      );
    } else {
      fetch("/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              if (res.status === "success") {
               
                let userInfo = res.data;
                this.props.setActiveLanguage(
                  userInfo?.attributes?.lang || "en"
                );
                this.props.dispatch(logInUserInfo(userInfo));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
              
                  toast.error(
                   this.props.translate(err[1])
                );
                this.props.navigate("/login");
              }
            });
          } else {
            this.props.navigate("/login");
            throw response;
          }
        })
        .catch((e) => {
   
            toast.error(
               "somethingWentWrong"
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withRoutes(commutator));
